import gripstudiologo from "../../assests/logo.svg";
import instagram from "../../assests/instagram-icon.svg";
import twitter from "../../assests/twitter-icon.svg";
import facebook from "../../assests/facebook-icon.svg";
import email from "../../assests/email-icon.svg";
import etsy from "../../assests/etsy-icon.svg";
import linkedin from "../../assests/linkedin-icon.svg";
import spotify from "../../assests/spotify-icon.svg";
import youtube from "../../assests/youtube-icon.svg";

import "./index.css";
import { Grid, Link,Box } from "@mui/material";

function FooterSection() {
  function getCurrentYear() {
    return new Date().getFullYear();
  }

  return (
    <Grid container className="footer-section footer-detail page-alignment">
      <Grid container xs={12} sm={6} md={8}>
        <Grid item md={9} className="footer-logo">
          <img src={gripstudiologo} alt="studio-logo" className="gripstudioLogo" loading="lazy"/>
          <div className="footer-para">
            Have questions? Need more info? Email us for additional details and support at &nbsp;
            <a href="mailto:hello@gripstudio.app" rel="noreferrer" className="underscore">
              Hello@GripStudio.App
            </a>
            &nbsp;or find us on your favorite social media platform.
          </div>
        </Grid>
      </Grid>
      <Grid container xs={12} sm={6} md={4} className="footer-links">
        <Grid className="social-media-detail">
          <Grid item>
            <Grid className="logo-alignment">
              <Grid item className="find-text">
                Find us on:
              </Grid>
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://www.instagram.com/gripstudio.app/" target="_blank">
                  <img src={instagram} className="align" alt="Instagram" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://open.spotify.com/user/31o5gj3kmfmuaqoainmbj6x5nzsy" target="_blank">
                  <img src={spotify} className="align" alt="Spotify" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://www.linkedin.com/in/gripstudio/" target="_blank">
                  <img src={linkedin} className="align" alt="Linkedin" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://www.facebook.com/profile.php?id=61550286072818" target="_blank">
                  <img src={facebook} className="align" alt="Facebook" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
          </Grid>
        </Grid>
        <Grid className="social-media-detail rest-icons">
          <Grid item>
          <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://twitter.com/GripStudioApp" target="_blank">
                  <img src={twitter} className="align" alt="Twitter" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://www.youtube.com/channel/UCH0QSwS7bh_lIg14WSlY8JA" target="_blank">
                  <img src={youtube} className="align" alt="Youtube" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <Link href="https://www.etsy.com/people/1n2ibyqnbfiac4k7?ref=hdr_user_menu-profile" target="_blank">
                  <img src={etsy} className="align" alt="Esty" loading="lazy"/>
                </Link>
              </Box>
              /GripStudio
            </Grid>
            <Grid className="logo-box">
              <Box className="icon-box">
                <a href="mailto:hello@gripstudio.app" rel="noreferrer">
                  <img src={email} className="align" alt="Email" loading="lazy"/>
                </a>
              </Box>
              /GripStudio
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="footer-copyright">
        <Grid container xs={12} sm={12} lg={6}>
          <Grid item className="copyright-text" xs={12} sm={12}>
            &copy; Copyrights {`${getCurrentYear()}`} All rights reserved.&nbsp; &nbsp;| &nbsp;&nbsp;
            <Link className="footer-link" href="https://gripstudio.s3.us-east-2.amazonaws.com/GripStudio+Terms+of+Use.pdf" target="_blank" rel="noopener noreferrer">
              <span className="footer-link">T&C</span>
            </Link>
            &nbsp; &nbsp;| &nbsp;&nbsp;
            <Link href="https://gripstudio.s3.us-east-2.amazonaws.com/GripStudio+Privacy+Policy.pdf" className="footer-link" target="_blank" rel="noopener noreferrer">
              <span className="footer-link">Privacy Policy</span>
            </Link>
          </Grid>
        </Grid>
        <Grid container xs={12} sm={12} md={6}>
          <Grid item xs={12} sm={12} className="download-text social-media-detail">
            Download for:
            <span>
              <Link href="https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1" target="_blank" className="download-link">
                &nbsp; Android
              </Link>
              &nbsp; &nbsp;| &nbsp;&nbsp;
              <Link href="https://apps.apple.com/in/app/gripstudio/id6473517079" target="_blank" className="download-link">
                iOS
              </Link>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FooterSection;
