import React from 'react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../index.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Grid } from "@mui/material";
import CrossFitLogo from "../../assests/CrossFitLogo.svg";
import functionalLogo from "../../assests/functionalLogo.svg";
import hiitLogo from "../../assests/hiitLogo.svg";
import yogaLogo from "../../assests/yogaLogo.svg";
import acrobaticsLogo from "../../assests/acrobaticsLogo.svg";
import cyclingLogo from "../../assests/cyclingLogo.svg";
import PilatesLogo from "../../assests/PilatesLogo.svg";
import AerobicsLogo from "../../assests/AerobicsLogo.svg";
import OutdoorLogo from "../../assests/OutdoorLogo.svg";
import BootCampLogo from "../../assests/BootCampLogo.svg";
import MixedMartialArtLogo from "../../assests/MixedMartialArtLogo.svg";
import PersonalTrainingLogo from "../../assests/PersonalTrainingLogo.svg";
import ClimbingLogo from "../../assests/ClimbingLogo.svg";
import SportsLessonsLogo from "../../assests/SportsLessonsLogo.svg";
import WeightTrainingLogo from "../../assests/WeightTrainingLogo.svg";
import WellnessRecoveryLogo from "../../assests/WellnessRecoveryLogo.svg";
import NutritionLogo from "../../assests/NutritionLogo.svg";
import NinjaWarriorLogo from "../../assests/NinjaWarriorLogo.svg";
import FitnessClubsLogo from "../../assests/FitnessClubsLogo.svg";
import BarreLogo from "../../assests/BarreLogo.svg";
import DanceLogo from "../../assests/DanceLogo.svg";
import CalisthenicsLogo from "../../assests/CalisthenicsLogo.svg";
import Events from "../../assests/events.svg";
import OlympicLiftingLogo from "../../assests/OlympicLiftingLogo.svg";

interface SliderProperty {
    image: string;
    title: string;
  }
  
  const slides: SliderProperty[] = [
    {
      image: CrossFitLogo,
      title: "CrossFit",
    },
    {
      image: functionalLogo,
      title: "Functional Fitness",
    },
    {
      image: hiitLogo,
      title: "HIIT",
    },
    {
      image: yogaLogo,
      title: "Yoga",
    },
    {
      image: acrobaticsLogo,
      title: "Acrobatics",
    },
    {
      image: cyclingLogo,
      title: "Cycling",
    },
    {
      image: AerobicsLogo,
      title: "Aerobics",
    },
    {
      image: PilatesLogo,
      title: "Pilates",
    },
    {
      image: ClimbingLogo,
      title: "Climbing",
    },
    {
      image: OutdoorLogo,
      title: "Outdoor",
    },
    {
      image: BootCampLogo,
      title: "Boot Camp",
    },
    {
      image: MixedMartialArtLogo,
      title: "Mixed Martial Art",
    },
    {
      image: PersonalTrainingLogo,
      title: "Personal Training",
    },
    {
      image: SportsLessonsLogo,
      title: "Sports Lessons",
    },
    {
      image: OlympicLiftingLogo,
      title: "Olympic Lifting",
    },
    {
      image: WeightTrainingLogo,
      title: "Weight Training",
    },
    {
      image: WellnessRecoveryLogo,
      title: "Wellness & Recovery",
    },
    {
      image: NutritionLogo,
      title: "Nutrition",
    },
    {
      image: NinjaWarriorLogo,
      title: "Ninja Warrior",
    },
    {
      image: FitnessClubsLogo,
      title: "Fitness Clubs",
    },
    {
      image: BarreLogo,
      title: "Barre",
    },
    {
      image: DanceLogo,
      title: "Dance",
    },
    {
      image: CalisthenicsLogo,
      title: "Calisth-enics",
    },
    {
      image: Events,
      title: "Events",
    },
  ];
const LazySwiperWrapper = () => {
  return<Swiper
  centeredSlides={true}
  speed={800}
  autoplay={{
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  loop={true}
  navigation={true}
  // slidesPerView={7}
  modules={[Autoplay, Pagination, Navigation]}
  className="mySwiper"

  slidesPerView= {10}

>
  {slides.map((slide, index) => (
    <SwiperSlide key={index}>
      <Grid
        lg={12}
        container
        direction="column"
        alignItems="center"
        className="vertical-box"
      >
        <Grid item className="box-class" lg={1}>
          <img src={slide.image} alt={slide.title} loading='lazy'/>
          {slide.title}
        </Grid>
      </Grid>
    </SwiperSlide>
  ))}
</Swiper>
};

export default LazySwiperWrapper;