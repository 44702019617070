import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { ExplorePremiumFeatures } from "../components/ExplorePremiumFeatures";
import HeaderSection from "../components/HeaderSection";
import GetEarly from "../components/GetEarly";
import GrowingFitness from "../components/GrowingFitness";
import HowGripStudioWork from "../components/HowGripStudioWork";
import SpotifyPlaylistAndPodcast from "../components/SpotifyPlaylistAndPodcast";
import GripStudioApp from "../components/GripStudioApp";
import FooterSection from "../components/FooterSection";

function UsersAndGyms() {
  const [isUsers, setIsUsers] = useState(true);

  useEffect(() => {
    let body = document.querySelector("body");
    console.log(body)
    body && body.style && body?.style?.setProperty("background", "#0c0c0c");
    console.log(body)
  }, [])

  return (
    <>
      <Grid container>
        <HeaderSection
          scrolled={true}
          isUsers={isUsers}
          setIsUsers={setIsUsers}
        />
        <GetEarly isUsers={isUsers} />
        {isUsers ? <ExplorePremiumFeatures /> : <GrowingFitness />}
        <SpotifyPlaylistAndPodcast />
        <HowGripStudioWork isUsers={isUsers} />
        <GripStudioApp />
        <FooterSection />
      </Grid>
    </>
  );
}

export default UsersAndGyms;
