import "./index.css";
import { Grid } from "@mui/material";
import ContactForm from "../ContactForm";

interface GetEarlyProps {
  isUsers: boolean;
}
function GetEarly({ isUsers }: GetEarlyProps) {
  return (
    <Grid item xs={12} className="get-early-section">
      <Grid container className="get-early-section bg-2">
        <Grid item xs={12} sm={4} lg={4} md={4} className="early-section">
          <Grid className="h1 get-early-heading">Get early access</Grid>
          <Grid className="p1">
            Sign up today to be one of the first 10,000 users to receive early
            access to GripStudio's network of gyms, studios, wellness solutions,
            and trainers.
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} lg={5} md={5} className="form">
          {isUsers ? (
            <ContactForm isUsers={isUsers} />
          ) : (
            <ContactForm isUsers={isUsers} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GetEarly;
