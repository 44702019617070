import { Grid, Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface IBulletPointProps {
  title: string;
  para: ReactNode;
}

const CommonBulletPoint = ({ title, para }: IBulletPointProps) => {
  return (
    <Grid>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontSize: "1.5rem",
          fontFamily: "Causten-700 !important",
          marginTop:'2rem'
        }}
      >
        {title}
      </Typography>
      {para}
    </Grid>
  );
};

export default CommonBulletPoint;
