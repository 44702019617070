import React from "react";
import { Container, Typography, Grid } from "@mui/material";
import Header from "../Header/Header";
import CommonBulletPoint from "../commonBulletPoint/commonBulletPoint";
import { List, ListItem, ListItemIcon } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
interface IPrivacyPolicyProps {
  isShowHeader: boolean;
}
const PrivacyPolicy = ({ isShowHeader }: IPrivacyPolicyProps) => {
  const commonStyle = {
    fontWeight: 500,
    fontSize: "1.3rem",
    fontFamily: "Causten-500 !important",
    padingBottom: "none",
  };

  return (
    <Grid container xs={12} lg={12} md={12}>
      {isShowHeader && <Header />}
      <Container sx={{ marginBlock: 10 }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            marginBottom: "1rem",
            fontSize: "3rem",
            textAlign: "center",
            fontFamily: "Causten-700 !important",
          }}
        >
          Privacy Policy
        </Typography>
        <CommonBulletPoint
          title={"Introduction"}
          para={
            <>
              {" "}
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                GRIPSTUDIO, LLC (“Company” or “We”) respects your privacy and is
                committed to protecting it through our compliance with this
                policy.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                This policy describes the types of information we may collect
                from you or that you may provide when you use our Platform, as
                defined in our terms of use located at
                https://www.gripstudio.app/Terms-and-conditions (the “Terms of
                Use”), which are incorporated by reference herein, including
                when you use any software application we provide or visit the
                website https://www.gripstudio.app/, and our practices for
                collecting, using, maintaining, protecting, and disclosing that
                information.
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                This policy applies to information we collect:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    On this Platform, including on or through any website or
                    software application related to this Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    In email, text, and other electronic messages between you
                    and this Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Through mobile and desktop applications you download to
                    access this Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    When you interact with our advertising and applications on
                    third-party websites and services, if those applications or
                    advertising include links to this policy.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                It does not apply to information collected by:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Us offline or through any other means, including on any
                    other website operated by Company or any third party; or
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Any third party, including through any application or
                    content (including advertising) that may link to or be
                    accessible from the Platform.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, your choice
                is not to use our Platform. By accessing or using this Platform,
                you agree to this privacy policy. This policy may change from
                time to time. Your continued use of this Platform after we make
                changes is deemed to be acceptance of those changes, so please
                check the policy periodically for updates.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title={"Children Under the Age of 13"}
          para={
            <>
              {" "}
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Our Platform is not intended for children under 13 years of age.
                No one under age 13 may provide any information to the Platform.
                We do not knowingly collect personal information from children
                under 13. If you are under 13, do not use or provide any
                information on this Platform, register on the Platform, make any
                purchases through the Platform, use any of the interactive or
                public comment features of this Platform, or provide any
                information about yourself to us, including your name, address,
                telephone number, email address, or any screen name or user name
                you may use. If we learn we have collected or received personal
                information from a child under 13 without verification of
                parental consent, we will delete that information. If you
                believe we might have any information from or about a child
                under 13, please contact us at Legal@GripStudio.App.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title={"Information We Collect About You and How We Collect It"}
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                We collect several types of information from and about users of
                our Platform, including information:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    By which you may be personally identified, such as name,
                    postal address, e-mail address, telephone number, or any
                    other identifier by which you may be contacted online or
                    offline (“personal information”);
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    That is about you but individually does not identify you;
                    and/or
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    About your internet connection, the equipment you use to
                    access our Platform, and usage details.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                We collect this information:
              </Typography>

              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Directly from you when you provide it to us.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Automatically as you navigate through the site. Information
                    collected automatically may include usage details, IP
                    addresses, and information collected through cookies, web
                    beacons, and other tracking technologies.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    From third parties, for example, our business partners.
                  </Typography>
                </ListItem>
              </List>
            </>
          }
        />
        <CommonBulletPoint
          title="Information You Provide to Us"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                The information we collect on or through our Platform may
                include:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Information that you provide by filling in forms on our
                    Platform. This includes information provided at the time of
                    registering to use our Platform, subscribing to our service,
                    posting material, or requesting further services. We may
                    also ask you for information when you report a problem with
                    our Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Records and copies of your correspondence (including email
                    addresses), if you contact us.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Your responses to surveys that we might ask you to complete
                    for research purposes.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Details of transactions you carry out through our Platform
                    and of the fulfillment of your orders. You may be required
                    to provide financial information before placing an order
                    through our Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Your use and interaction with various features of the
                    Platform.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You also may provide information to be published or displayed
                (hereinafter, “posted”) on public areas of the Platform, or
                transmitted to other users of the Platform or third parties,
                including User Contributions, as defined in the Terms of Use.
                Such material is created or posted at your own risk. Although we
                limit access to certain pages, please be aware that no security
                measures are perfect or impenetrable. Additionally, we cannot
                control the actions of other users of the Platform with whom you
                may choose to share your User Contributions. Therefore, we
                cannot and do not guarantee that your User Contributions will
                not be viewed by unauthorized persons.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Information We Collect Through Automatic Data Collection Technologies"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                As you navigate through and interact with our Platform, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Details of your visits to our Platform, including traffic
                    data, location data, logs, and other communication data and
                    the resources that you access and use on the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Information about your computer and internet connection,
                    including your IP address, operating system, and browser
                    type.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                The information we collect automatically is only statistical
                data and does not include personal information, but we may
                maintain it or associate it with personal information we collect
                in other ways or receive from third parties. It helps us to
                improve our Platform and to deliver a better and more
                personalized service, including by enabling us to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Estimate our audience size and usage patterns.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Store information about your preferences, allowing us to
                    customize our Platform according to your individual
                    interests.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Speed up your searches.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Recognize you when you return to our Platform.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                As an example, the technologies we may use for this automatic
                data collection may include or be similar to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Cookies (or browser cookies). A cookie is a small file
                    placed on the hard drive of your computer. You may refuse to
                    accept browser cookies by activating the appropriate setting
                    on your browser. However, if you select this setting you may
                    be unable to access certain parts of our Platform. Unless
                    you have adjusted your browser setting so that it will
                    refuse cookies, our system will issue cookies when you
                    direct your browser to our Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Flash Cookies. Certain features of our Platform may use
                    local stored objects (or Flash cookies) to collect and store
                    information about your preferences and navigation to, from,
                    and on our Platform. Flash cookies are not managed by the
                    same browser settings as are used for browser cookies.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Web Beacons. Pages of our the Platform and our e-mails may
                    contain small electronic files known as web beacons (also
                    referred to as clear gifs, pixel tags, and single-pixel
                    gifs) that permit Company, for example, to count users who
                    have visited those pages or opened an email and for other
                    related website statistics (for example, recording the
                    popularity of certain website content and verifying system
                    and server integrity).
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                We do not collect personal information automatically, but we may
                tie this information to personal information about you that we
                collect from other sources or you provide to us.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Third-Party Use of Cookies and Other Tracking Technologies"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Some content or applications, including advertisements, on the
                Platform are served by third-parties, including advertisers, ad
                networks and servers, content providers, and application
                providers. These third parties may use cookies alone or in
                conjunction with web beacons or other tracking technologies to
                collect information about you when you use our website. The
                information they collect may be associated with your personal
                information or they may collect information, including personal
                information, about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with interest-based (behavioral)
                advertising or other targeted content.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="How We Use Your Information"
          para={
            <>
              <Typography variant="h6" sx={{ ...commonStyle }}>
                We use information that we collect about you or that you provide
                to us, including any personal information:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To present our Platform and its contents to you.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To provide you with information, products, or services that
                    you request from us.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To fulfill any other purpose for which you provide it.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To provide you with notices about your account or
                    subscription, including expiration and renewal notices.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To carry out our obligations and enforce our rights arising
                    from any contracts entered into between you and us,
                    including for billing and collection.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To notify you about changes to our Platform or any products
                    or services we offer or provide through it.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To allow you to participate in interactive features on our
                    Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    In any other way we may describe when you provide the
                    information.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    For any other purpose with your consent.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may also use your information to contact you about our own
                and third-parties’ goods and services that may be of interest to
                you. If you do not want us to use your information in this way,
                please adjust your user preferences in your account profile or
                contact us.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may use the information we have collected from you to enable
                us to display advertisements to our advertisers’ target
                audiences. Even though we do not disclose your personal
                information for these purposes without your consent, if you
                click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Disclosure of Your Information"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may disclose aggregated information about our users, and
                information that does not identify any individual, without
                restriction.
              </Typography>
              <Typography variant="h6" sx={{ ...commonStyle }}>
                We may disclose personal information that we collect or you
                provide as described in this privacy policy:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To our subsidiaries and affiliates.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To contractors, service providers, and other third parties
                    we use to support our business.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Company’s assets,
                    whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal
                    information held by Company about our Platform users is
                    among the assets transferred.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To third parties to market their products or services to you
                    if you have not opted out of these disclosures.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To fulfill the purpose for which you provide it.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    For any other purpose disclosed by us when you provide the
                    information.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    With your consent.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="h6" sx={{ ...commonStyle }}>
                We may also disclose your personal information:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To enforce or apply our Terms of Use, Rental Terms, Terms of
                    Sale, and other agreements, including for billing and
                    collection purposes.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    If we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of GRIPSTUDIO, LLC,
                    our customers, or others. This includes exchanging
                    information with other companies and organizations for the
                    purposes of fraud protection and credit risk reduction.
                  </Typography>
                </ListItem>
              </List>
            </>
          }
        />

        <CommonBulletPoint
          title="Choices About How We Use and Disclose Your Information"
          para={
            <>
              <Typography variant="h6" sx={{ ...commonStyle }}>
                We strive to provide you with choices regarding the personal
                information you provide to us. Where applicable, we may provide
                you with the following control over your information:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Tracking Technologies and Advertising. You can set your
                    browser to refuse all or some browser cookies, or to alert
                    you when cookies are being sent. To learn how you can manage
                    your Flash cookie settings, visit the Flash player settings
                    page on Adobe’s website. If you disable or refuse cookies,
                    please note that some parts of this site may then be
                    inaccessible or not function properly.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Disclosure of Your Information for Third-Party Advertising.
                    If we plan to share your personal information with
                    unaffiliated or non-agent third parties for promotional
                    purposes, we will provide you the option to opt-out by
                    logging into the Platform and adjusting your user
                    preferences in your account profile or by sending us an
                    email with your request to Legal@GripStudio.App.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Promotional Offers from Company. If we plan to use your
                    contact information to promote our own or third parties’
                    products or services, we will provide you the option to
                    opt-out by logging into the Platform and adjusting your user
                    preferences in your account profile or by sending us an
                    email stating your request to Legal@GripStudio.App. This opt
                    out does not apply to information provided to Company as a
                    result of a product purchase, warranty registration, product
                    service experience or other transactions.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Targeted Advertising. If we plan to use information that we
                    collect or that you provide to us to deliver advertisements
                    according to our advertisers’ target-audience preferences,
                    we will provide you the option to opt-out by adjusting your
                    user preferences in your account profile or by sending us an
                    email stating your request to Legal@GripStudio.App.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We do not control third parties’ collection or use of your
                information to serve interest-based advertising. However these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Accessing and Correcting Your Information"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You can review and change your personal information by logging
                into the Platform and visiting your account profile page.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You may also send us an email at Legal@GripStudio.App to request
                access to, correct or delete any personal information that you
                have provided to us. We cannot delete your personal information
                except by also deleting your user account. We may not
                accommodate a request to change information if we believe the
                change would violate any law or legal requirement or cause the
                information to be incorrect.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If you delete your User Contributions from the Platform, copies
                of your User Contributions may remain viewable in cached and
                archived pages, or might have been copied or stored by other
                Platform users. Proper access and use of information provided on
                the Platform, including User Contributions, is governed by our
                Terms of Use.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Data Security"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We have implemented industry standard measures intended to
                secure your personal information from accidental loss and from
                unauthorized access, use, alteration, and disclosure.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Platform, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone. We urge you to be careful about
                giving out information in public areas of the Platform like
                message boards. The information you share in public areas may be
                viewed by any user of the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Platform. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Platform.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Additional Rights"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                While we have provided you various account settings and controls
                for managing your information, you may have additional or
                complementary rights under one or more state or local laws, some
                of which are noted below.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Residents of California, thanks to section 1798.83 of the
                California Civil Code, may request, once per calendar year, from
                companies conducting business in California a list of all third
                parties to whom the company has disclosed certain personally
                identifiable information as defined under California law during
                the preceding year for third-party direct-marketing purposes. In
                your request, please attest to your being a California resident
                and provide a current California address for our response.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If you are a California resident and under the age of 18, and a
                registered user of this Platform, section 22581 of the
                California Business and Professions Code permits you to request
                and obtain the removal of content that you have publicly posted.
                Please note that your request will not ensure a complete removal
                of the content and that in some cases the statute may not
                require or allow removal even if requested.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Nevada residents who wish to exercise their sale opt-out rights
                under Nevada Revised Statutes Chapter 603A may submit a request
                to this designated address: Legal@GripStudio.App.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Changes to Our Privacy Policy"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                It is our policy to post any changes we make to our privacy
                policy on this page with a notice that the privacy policy has
                been updated on the Platform home page. If we make material
                changes to how we treat our users’ personal information, we will
                notify you by email to the email address specified in your
                account or through a notice on the Platform home page. The date
                the privacy policy was last revised is identified at the top of
                the page. You are responsible for ensuring we have an up-to-date
                active and deliverable email address for you, and for
                periodically visiting our Platform and this privacy policy to
                check for any changes.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Contact Information"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at:
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                Legal@GripStudio.App
              </Typography>
            </>
          }
        />
      </Container>
    </Grid>
  );
};

export default PrivacyPolicy;
