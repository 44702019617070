import React from "react";
import { Grid, Button } from "@mui/material";
import "./HeaderChipSelection.css";
import { HeaderSectionProps } from "../HeaderSection/index";
import hitIcon from "../../assests/HIIT2.svg";
import lotus from "../../assests/lotus.svg";

const handleDownload = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    const url = 'https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1';
    window.open(url, '_blank');
  } else {
    const url = 'https://apps.apple.com/in/app/gripstudio/id6473517079';
    window.open(url, '_blank');
  }
}

export const HeaderChipSelection = ({
  isUsers,
  setIsUsers,
}: HeaderSectionProps) => {
  return (
    <Grid item xs={12} md={9} lg={9} className="header-section-chip">
      <ul className="header-chip">
        <li className={`header-link ${isUsers ? "active" : ""}`}>
          <Button className="users-link" onClick={() => setIsUsers(true)}>
            <img src={hitIcon} alt={"Hit icon"} className="nav-icon" loading="lazy"/>
            &nbsp; For Guest & Users
          </Button>
        </li>
        <li className={`header-link ${!isUsers ? "active" : ""}`}>
          <Button className="gym-link" onClick={() => setIsUsers(false)}>
            <img src={lotus} alt={"lotus icon"} className="nav-icon" loading="lazy"/>
            &nbsp; For Gyms, Trainers, and Wellness Studios
          </Button>
        </li>
        <li className="download-header-button">
          <Button className="active-btn download-button" onClick={handleDownload}>
            download now
          </Button>
        </li>
      </ul>
    </Grid>
  );
};
