import { Grid } from "@mui/material";
import "./index.css";

interface GenericHeadingProps {
  heading: string;
  paragraph: string;
  color?: string;
}

const GenericHeading = ({ heading, paragraph, color }: GenericHeadingProps) => {
  return (
    <Grid item xs={12} className="heading-section">
      <Grid container direction="row" className="generic-flex generic-heading">
        <Grid item className="genricHeading-text causten-700">
          <div color={color}>{heading}</div>
        </Grid>
        <Grid item className="genricHeading-para causten-400">
          <div color={color}>{paragraph}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenericHeading;
