import { Card, CardContent } from "@mui/material";

export default function PlaylistCard({ children }: any) {
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "#181818",
        borderRadius: "29px",
        padding: "6px",
        margin: "0",
      }}
    >
      {/* <CardHeader title="Spotify Playlist"  titleTypographyProps={{ color: "white", fontFamily: "Causten", fontWeight: 400, fontSize: '2rem' }} /> */}
      <CardContent>{children}</CardContent>
    </Card>
  );
}
