import { Grid, Typography } from "@mui/material";
import "./BulletPoint.css";

export const BulletPoint = ({
  image,
  heading,
  subHeading,
  onClick,
  isSelected,
}: {
  image: string;
  heading: string;
  subHeading: string;
  onClick?: () => void;
  isSelected?: boolean | undefined;
}) => {
  return (
    <Grid container className={`bullet-point`} onClick={onClick}>
      <Grid className="image-container" lg={2} md={2}>
        <img src={image} className="bullet-images" alt="Bullet Icon" loading="lazy" />
      </Grid>
      <Grid className="bullet-container" lg={10} md={10}>
        <Typography
          variant="h4"
          className={`bullet-heading ${isSelected ? "selected" : ""}`}
        >
          {heading}
        </Typography>
        <Typography className={`bullet-para ${isSelected ? "selected" : ""}`}>
          {subHeading}
        </Typography>
      </Grid>
    </Grid>
  );
};
