import { Grid, Typography } from "@mui/material";
import "./index.css";
import step1 from "../../assests/step1.svg";
import step2 from "../../assests/step2.svg";
import step3 from "../../assests/step3.svg";
import breakLine from "../../assests/break-Line.svg";
interface IHowGripStudioWorksProps {
  isUsers: boolean;
}

export default function index(props: IHowGripStudioWorksProps) {
  const { isUsers } = props;
  return (
    <Grid className="gripStudio-wrapper1">
      <Grid>
        <Typography className="heading" variant="h1">
          How GripStudio works?
        </Typography>
        <Typography
          className="heading-para"
          variant="h6"
          sx={{
            textAlign: "center",
            paddingInline: "30rem",
            paddingBlock: "2rem",
            fontSize: "1.125rem",
          }}
        >
          {isUsers
            ? `Follow the steps below to expand your fitness and wellness adventure. GripStudio is a free platform with in-app purchases built to support fitness-goers of all skill levels. Let's get started!`
            : `Follow the steps below to reach your desired audience. GripStudio is a
          free platform with in-app purchases built to support fitness-goers of
          all skill levels. Let's start growing your business!`}
        </Typography>
      </Grid>

      <Grid className="gripStudio-wrapper" sx={{position:'relative'}}>
        <Grid className="content-wrapper" lg={3} md={4} sm={12}>
          <img src={step1} alt="Step One" loading="lazy"/>
          <Grid className="content">
            <a href= 'https://apps.apple.com/in/app/gripstudio/id6473517079'>
              <Typography className="title">Download Now</Typography>
              <Typography className="title-para">
                {isUsers
                  ? "Click the link and become one of the first GripStudio Users."
                  : "Click the link to become one of the first GripStudio Partners."}
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Grid className="break-line">
          <img src={breakLine} alt="Line" loading="lazy"/>
        </Grid>
        <Grid className="content-wrapper" lg={3} md={4} sm={12}>
          <img src={step2} alt="Step two" loading="lazy"/>
          <Grid className="content">
            <Typography className="title">
              {" "}
              {isUsers ? "Book" : "Publish"}
            </Typography>
            <Typography className="title-para">
              {isUsers
                ? "Found a class or event you like? Reserve your spot within a few clicks."
                : "Publish your customized schedule, activities, and classes to best fit your business."}
            </Typography>
          </Grid>
        </Grid>
        <Grid className="break-line">
          <img src={breakLine} alt="Line breaker" loading="lazy"/>
        </Grid>
        <Grid className="content-wrapper" lg={4} md={4} sm={12}>
          <img src={step3} className="step3-image" alt="Step three" loading="lazy"/>
          <Grid className="content">
            <Typography className="title">
              {isUsers ? "Enjoy!" : "Grow!"}
            </Typography>
            <Typography className="title-para">
              {isUsers
                ? "Don't sweat the small stuff. With the help of our intuitive platform, give yourself the confidence to take on whatever fitness journey is ahead of you."
                : "Don't sweat the small stuff. With the help of our intuitive platform, grow your business with automation and personalization. Enjoy your growth!"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
