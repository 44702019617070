import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Grid, IconButton, InputAdornment, Box } from "@mui/material";
import axios from "axios";
import { UserCredential, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";
import { UserType } from "../constant";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.css";

interface ContactFormProps {
  isUsers: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ isUsers }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    emailId: "",
    password: "",
    phoneNumber: "",
    address: "",
    country: "+1",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    emailId: "",
    password: "",
    phoneNumber: "",
    address: "",
  });

  // Add a loading state
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof typeof formData;
    validateForm(fieldName);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = Object.keys(formData).every((fieldName) =>
      validateForm(fieldName as keyof typeof formData)
    );

    if (isValid) {
      setIsLoading(true); // Set loading state only when the form is valid

      createUserWithEmailAndPassword(
        auth,
        formData.emailId,
        formData.password
      )
        .then((userCredential: UserCredential) =>
          userCredential.user.getIdToken()
        )
        .then((token) => {
          const postData = {
            ...formData,
            userType: isUsers ? UserType.User : UserType.Gym,
          };

          const { password, ...postDataWithoutPassword } = postData;
          return axios.post(
            `${process.env.REACT_APP_SERVER_URL}/users/early-signup`,
            postDataWithoutPassword,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        })
        .then((response) => {
          toast.success(
            "Congrats! you have been added to the early access request list. You will be informed of next steps via email."
          );
          resetForm();
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            toast.error("This email address has already been registered");
          } else {
            toast.error("Something went wrong!");
          }
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state when submission is complete
        });
    }
  };

  const validateForm = (fieldName: keyof typeof formData) => {
    let isValid = true;
    const newErrors = { ...errors };

    switch (fieldName) {
      case "fullName":
        // Validate name
        if (!formData.fullName.trim()) {
          newErrors.fullName = "Name is required";
          isValid = false;
        } else if (!/^[\w\s]{1,200}$/.test(formData.fullName)) {
          newErrors.fullName =
            "Invalid name format. Name can have alphanumeric characters, spaces, and must not exceed 200 characters.";
          isValid = false;
        } else {
          newErrors.fullName = "";
        }
        break;

      case "emailId":
        // Validate email
        if (!formData.emailId.trim()) {
          newErrors.emailId = "Email is required";
          isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.emailId)) {
          newErrors.emailId = "Invalid email format";
          isValid = false;
        } else {
          newErrors.emailId = "";
        }
        break;

      case "password":
        // Validate password
        if (!formData.password.trim()) {
          newErrors.password = "Password is required";
          isValid = false;
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(formData.password)) {
          newErrors.password =
            "Password must contain at least one number, one uppercase letter, and one lowercase letter";
          isValid = false;
        } else {
          newErrors.password = "";
        }
        break;

      case "phoneNumber":
        if (!formData.phoneNumber.trim()) {
          newErrors.phoneNumber = "Phone number is required";
          isValid = false;
        } else if (!/^\d+$/.test(formData.phoneNumber)) {
          newErrors.phoneNumber = "Phone number must contain only digits";
          isValid = false;
        } else if (formData.phoneNumber.length > 15) {
          newErrors.phoneNumber = "Phone number cannot exceed 15 digits";
          isValid = false;
        } else {
          newErrors.phoneNumber = "";
        }
        break;
    }
    setErrors(newErrors);
    return isValid;
  };

  const resetForm = () => {
    setFormData({
      fullName: "",
      emailId: "",
      password: "",
      phoneNumber: "",
      address: "",
      country: "+1",
    });
    setErrors({
      fullName: "",
      emailId: "",
      password: "",
      phoneNumber: "",
      address: "",
    });
  };
  useEffect(() => {
    resetForm();
  }, [isUsers]);

  const isFormValid = Object.values(errors).every((error) => error === "");

  return (
    <Grid container xs={12} sm={10} md={10} xl={10} className="form-wrapper">
      <form onSubmit={handleSubmit}>
        <TextField
          name="fullName"
          label="Name"
          value={formData.fullName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!errors.fullName}
          helperText={errors.fullName}
          InputProps={{
            sx: {
              backgroundColor: "#1F1F1F",
              borderRadius: "10px",
              color: "#FFFFFF",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "#8B8B8B",
            },
          }}
        />
        <TextField
          name="emailId"
          label={isUsers === true ? "Your Email" : "Your Business Email"}
          value={formData.emailId}
          onChange={handleInputChange}
          onBlur={handleBlur}
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!errors.emailId}
          helperText={errors.emailId}
          InputProps={{
            sx: {
              backgroundColor: "#1F1F1F",
              borderRadius: "10px",
              color: "#FFFFFF",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "#8B8B8B",
            },
          }}
        />
        <TextField
          name="password"
          label="Password"
          value={formData.password}
          onChange={handleInputChange}
          onBlur={handleBlur}
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!errors.password}
          helperText={errors.password}
          type={showPassword ? "text" : "password"}
          InputProps={{
            sx: {
              backgroundColor: "#1F1F1F",
              borderRadius: "10px",
              color: "#FFFFFF",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prevShow) => !prevShow)}
                  edge="end"
                  sx={{ color: "#E7E3FCAD" }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              color: "#8B8B8B",
            },
          }}
        />

        <TextField
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          onBlur={handleBlur}
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Box sx={{ color: "#FFF", pr: "10px" }}> +1</Box>
              </InputAdornment>
            ),
            sx: {
              backgroundColor: "#1F1F1F",
              borderRadius: "10px",
              color: "#FFFFFF",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "#8B8B8B",
            },
          }}
        />

        <TextField
          name="address"
          label="Address (Optional)"
          value={formData.address}
          onChange={handleInputChange}
          onBlur={handleBlur}
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          InputProps={{
            sx: {
              backgroundColor: "#1F1F1F",
              borderRadius: "10px",
              sx: { height: "6rem" },
              color: "#FFFFFF",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "#8B8B8B",
            },
          }}
        />
        <Button
          className="active-btn"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </form>
    </Grid>
  );
};

export default ContactForm;
