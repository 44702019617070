import "./index.css";
import playstore from "../../assests/playstore.svg";
import cellPhone from "../../assests/cell-phone.svg";
import { Box, Grid, Typography } from "@mui/material";
function GripStudioApp() {
  return (
    <>
      <Grid container className="baseball page-alignment generic-flex">
        <Grid
          item
          xs={11}
          sm={8}
          md={7}
          lg={8}
          className="text-section divider"
        >
          <div className="approved-app">
            GripStudio <br /> available for all devices
          </div>
          <div className="available-para-text">
            GripStudio is a multi-platform app to ensure that everyone has the
            opportunity to be in charge of their personal health and fitness.
            &nbsp;
            {/* <span style={{ color: "#ffffff", fontWeight: 700 }}>
              Download coming soon!
            </span> */}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={5}
          lg={4}
          className="text-section android-section App-store"
        >
          {/* First Box */}
          <Box className={"doenload-App-store"}>
            <img
              src={playstore}
              alt="Play Store"
              style={{ marginRight: "10px" }}
              loading="lazy"
            />
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1" target="_blank" rel="noreferrer">
                <Typography
                  variant="h5"
                  component="p"
                  className="button-sub-title"
                >
                  Download from
                </Typography>
                <Typography variant="h2" component="p" className="button-title">
                  Google Play
                </Typography>
              </a>              
            </div>
          </Box>
          {/* Second Box */}
          <Box className={"doenload-App-store"}>
            <img
              src={cellPhone}
              alt="Cell Phone"
              style={{ marginRight: "10px" }}
              loading="lazy"
            />
            <div>
              <a href="https://apps.apple.com/in/app/gripstudio/id6473517079" target="_blank" rel="noreferrer">
                <Typography
                  variant="h5"
                  component="p"
                  className="button-sub-title"
                >
                  Download from
                </Typography>
                <Typography variant="h2" component="p" className="button-title">
                  app store
                </Typography>
              </a>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default GripStudioApp;
