import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import Header from "../Header/Header";
import CommonBulletPoint from "../commonBulletPoint/commonBulletPoint";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
interface ITermsAndConditionProps {
  isShowHeader: boolean;
}

const TermsAndConditions = ({ isShowHeader }: ITermsAndConditionProps) => {
  const commonStyle = {
    fontWeight: 500,
    fontSize: "1.3rem",
    fontFamily: "Causten-500 !important",
    padingBottom: "none",
  };

  return (
    <Grid container xs={12} lg={12} md={12}>
      {isShowHeader && <Header />}
      <Container sx={{ marginBlock: 10 }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            marginBottom: "1rem",
            fontSize: "3rem",
            textAlign: "center",
            fontFamily: "Causten-700 !important",
          }}
        >
          Terms of Use
        </Typography>
        <CommonBulletPoint
          title="Acceptance of the Terms of Use"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                These terms of use are entered into by and between You and
                GRIPSTUDIO, LLC (“Company,” “we,” or “us”). The following terms
                and conditions, together with any documents they incorporate by
                reference (collectively, “Terms of Use”), govern your access to
                and use of GripStudio, including any related software
                applications, mobile applications, or websites, including the
                website at https://www.gripstudio.app/ , and including any
                content, functionality, and services offered on or through
                https://www.gripstudio.app/ (collectively, the “Platform”).
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Please read the Terms of Use carefully before you start to use
                the Platform. By using the Platform or by clicking to accept or
                agree to the Terms of Use when this option is made available to
                you, you accept and agree to be bound and abide by these Terms
                of Use and our Privacy Policy, found at
                https://www.gripstudio.app/Privacy-policy , incorporated herein
                by reference. If you do not want to agree to these Terms of Use
                or the Privacy Policy, you must not access or use the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                This Platform is offered and available to registered users who
                are 13 years of age or older, and reside in the United States or
                any of its territories or possessions (“Users”). In order to
                purchase or use any paid services offered through the Platform,
                you must be at least 18 years old or have your parent’s
                permission. By using this Platform, you represent and warrant
                that you are of legal age to form a binding contract with
                Company and, if you are under the age of 18, that you do so with
                your parent’s permission. If you do not meet all of these
                requirements, you must not access or use the Platform.
              </Typography>
            </>
          }
        />

        <CommonBulletPoint
          title="Electronic Communications"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Visiting www.gripstudio.app or sending emails to GripStudio
                constitutes electronic communications. You consent to receive
                electronic communications and you agree that all agreements,
                notices, disclosures and other communications that we provide to
                you electronically, via email and on the Site, satisfy any legal
                requirement that such communications be in writing.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Changes to the Terms of Use"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may revise and update these Terms of Use from time to time in
                our sole discretion. All changes are effective immediately when
                we post them, and apply to all access to and use of the Platform
                thereafter. Your continued use of the Platform following the
                posting of revised Terms of Use means that you accept and agree
                to the changes. You are expected to check this page from time to
                time so you are aware of any changes, as they are binding on
                you.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Accessing the Platform and Account Security"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We reserve the right to withdraw or amend this Platform, and any
                service or material we provide on the Platform, in our sole
                discretion without notice. We will not be liable if for any
                reason all or any part of the Platform is unavailable at any
                time or for any period. From time to time, we may restrict
                access to some parts of the Platform, or the entire Platform, to
                Users.
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You are responsible for both:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Making all arrangements necessary for you to have access to
                    the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Ensuring that all persons who access the Platform through
                    your internet connection are aware of these Terms of Use and
                    comply with them.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                To access the Platform or some of the resources it offers, you
                may be asked to provide certain registration details or other
                information. It is a condition of your use of the Platform that
                all the information you provide on the Platform is correct,
                current, and complete. You agree that all information you
                provide to register with this Platform or otherwise, including,
                but not limited to, through the use of any interactive features
                on the Platform, is governed by our Privacy Policy, and you
                consent to all actions we take with respect to your information
                consistent with our Privacy Policy.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If you choose, or are provided with, a user name, password, or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential, and
                you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not
                to provide any other person with access to this Platform or
                portions of it using your user name, password, or other security
                information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or
                any other breach of security. You also agree to ensure that you
                exit from your account at the end of each session. You should
                use particular caution when accessing your account from a public
                or shared computer so that others are not able to view or record
                your password or other personal information.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We have the right to disable any user name, password, or other
                identifier, whether chosen by you or provided by us, at any time
                in our sole discretion for any or no reason, including if, in
                our opinion, you have violated any provision of these Terms of
                Use.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Beta Services"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Subject to your compliance with the terms herein, we may provide
                you access, in our sole discretion and on a trial basis, to
                potential new services or features that are in development and
                not yet available to all Users (“Beta Services”). TO THE EXTENT
                PERMITTED UNDER APPLICABLE LAW, YOU AGREE THAT THESE BETA
                SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
                WITHOUT ANY WARRANTY, SUPPORT, MAINTENANCE, STORAGE, SLA, OR
                INDEMNITY OBLIGATIONS OF ANY KIND FROM GRIPSTUDIO, LLC. WITH
                RESPECT TO BETA SERVICES, YOU FURTHER ACKNOWLEDGE AND AGREE THAT
                BETA SERVICES MAY NOT BE COMPLETE OR FULLY FUNCTIONAL AND MAY
                CONTAIN BUGS, ERRORS, OMISSIONS, AND OTHER PROBLEMS FOR WHICH
                PODIUM WILL NOT BE RESPONSIBLE. ACCORDINGLY, ANY USE OF THE BETA
                SERVICES IS AT YOUR SOLE RISK.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Intellectual Property Rights"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The Platform and its entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by
                Company, its licensors, or other providers of such material and
                are protected by United States and international copyright,
                trademark, patent, trade secret, and other intellectual property
                or proprietary rights laws.
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                These Terms of Use permit you to use the Platform for your
                personal, non-commercial use only. You must not reproduce,
                distribute, modify, create derivative works of, publicly
                display, publicly perform, republish, download, store, or
                transmit any of the material on our Platform, except as follows:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Your computer may temporarily store copies of such materials
                    in RAM incidental to your accessing and viewing those
                    materials.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    You may store files that are automatically cached by your
                    Web browser for display enhancement purposes.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    You may print or download one copy of a reasonable number of
                    pages of the Platform for your own personal, non-commercial
                    use and not for further reproduction, publication, or
                    distribution.{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    If we provide desktop, mobile, or other applications for
                    download, you may download a single copy to your computer or
                    mobile device solely for your own personal, non-commercial
                    use, provided you agree to be bound by our end user license
                    agreement for such applications.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    If we provide social media features with certain content,
                    you may take such actions as are enabled by such features.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You must not:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Modify copies of any materials from this site.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Use any illustrations, photographs, video or audio
                    sequences, or any graphics separately from the accompanying
                    text.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Delete or alter any copyright, trademark, or other
                    proprietary rights notices from copies of materials from
                    this site.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You must not access or use for any commercial purposes any part
                of the Platform or any services or materials available through
                the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the Platform
                in breach of the Terms of Use, your right to use the Platform
                will stop immediately and you must, at our option, return or
                destroy any copies of the materials you have made. No right,
                title, or interest in or to the Platform or any content on the
                Platform is transferred to you, and all rights not expressly
                granted are reserved by Company. Any use of the Platform not
                expressly permitted by these Terms of Use is a breach of these
                Terms of Use and may violate copyright, trademark, and other
                laws.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Company may collect and store data and information related to
                your use of the Platform that is used by Company in an aggregate
                and anonymized manner, including to compile statistical and
                performance information related to the provision and operation
                of the Platform (“Aggregate Data”).
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You hereby unconditionally and irrevocably grant to Company an
                assignment of all right, title, and interest in and to the
                Aggregate Data, including all intellectual property rights
                relating thereto, and applicable to all Aggregate Date held by
                Company, whether created or acquired by Company prior to or
                after your acceptance of these Terms of Use.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Trademarks"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The name, logo, related terms, product and service names,
                designs, slogans, and any other trademarks, brand elements or
                material otherwise identifying Company as the source of goods or
                services are property of Company (or its affiliates or
                licensors). You must not use such marks without the prior
                written permission of Company. All other names, logos, product
                and service names, designs, and slogans on this Platform are the
                trademarks of their respective owners.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Prohibited Uses"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You may use the Platform only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use the
                Platform:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    In any way that violates any applicable federal, state,
                    local, or international law or regulation (including,
                    without limitation, any laws regarding the export of data or
                    software to and from the US or other countries).
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    For the purpose of exploiting, harming, or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content, asking for personally identifiable
                    information, or otherwise.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To send, knowingly receive, upload, download, use, or re-use
                    any material that does not comply with the Content Standards
                    set out in these Terms of Use.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To transmit, or procure the sending of, any advertising or
                    promotional material, including any “junk mail,” “chain
                    letter,” “spam,” or any other similar solicitation.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To impersonate or attempt to impersonate Company, a Company
                    employee, another User, or any other person or entity
                    (including, without limitation, by using email addresses or
                    screen names associated with any of the foregoing).
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of the Platform, or which, as
                    determined by us, may harm Company or Users of the Platform,
                    or expose them to liability.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                Additionally, you agree not to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Use the Platform in any manner that could disable,
                    overburden, damage, or impair the site or interfere with any
                    other party’s use of the Platform, including their ability
                    to engage in real time activities through the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Use any robot, spider, or other automatic device, process,
                    or means to access the Platform for any purpose, including
                    monitoring or copying any of the material on the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Use any manual process to monitor or copy any of the
                    material on the Platform, or for any other purpose not
                    expressly authorized in these Terms of Use, without our
                    prior written consent.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Use any device, software, or routine that interferes with
                    the proper working of the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Introduce any viruses, Trojan horses, worms, logic bombs, or
                    other material that is malicious or technologically harmful.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of the Platform, the server on
                    which the Platform is stored, or any server, computer, or
                    database connected to the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Attack the Platform via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Otherwise attempt to interfere with the proper working of
                    the Platform.
                  </Typography>
                </ListItem>
              </List>
            </>
          }
        />
        <CommonBulletPoint
          title="User Contributions"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The Platform may contain, from time to time, message boards,
                chat rooms, personal web pages or profiles, forums, bulletin
                boards, tools, and other interactive features (collectively,
                “Interactive Services”) that allow Users to create, post,
                submit, publish, display, store, transmit, or otherwise create
                content or materials that are intended to be shared with other
                users, or are otherwise of a non-confidential nature
                (collectively, “User Contributions”) on or through the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                All User Contributions must comply with the Content Standards
                set out in these Terms of Use.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Any User Contribution you create through the Platform will be
                considered non-confidential and non-proprietary. By creating any
                User Contribution on the Platform, you grant us and our
                affiliates and service providers, and each of their and our
                respective licensees, successors, and assigns the right to use,
                reproduce, modify, perform, display, distribute, and otherwise
                disclose to third parties any such material for any
                purpose/according to your account settings.
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You represent and warrant that:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    You own or control all rights in and to the User
                    Contributions and have the right to grant the license
                    granted above to us and our affiliates and service
                    providers, and each of their and our respective licensees,
                    successors, and assigns.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    All of your User Contributions do and will comply with these
                    Terms of Use
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You understand and acknowledge that you are responsible for any
                User Contributions you Create, and you, not Company, have full
                responsibility for such content, including its legality,
                reliability, accuracy, and appropriateness.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We are not responsible or liable to any third party for the
                content or accuracy of any User Contributions created by you or
                any other User of the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We are not responsible or liable to any third party for the
                content or accuracy of any User Contributions created by you or
                any other User of the Platform.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Digital Millennium Copyright Act"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                GRIPSTUDIO, LLC will respond to notices of alleged copyright
                infringement that comply with applicable law and are properly
                delivered to us. We reserve the right to remove content on the
                Platform alleged to be infringing without prior notice and in
                its sole discretion. In appropriate circumstances, we will also
                terminate the account of 4a User determined to be a repeat
                infringer. If you believe that your work has been copied or used
                via the Platform in a way that constitutes copyright
                infringement, you may notify us by providing the following
                information (as required by the Online Copyright Infringement
                Liability Limitation Act of the Digital Millennium Copyright
                Act, 17 U.S.C. § 512):
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    The copyright owner’s name (or company name), your full
                    legal name, title, job position, and relationship to the
                    copyright owner, and your full mailing address, telephone
                    number, and email address.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    A description of the copyrighted work that you claim has
                    been infringed.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    A description of the material on the Service that you claim
                    is infringing (including a description of where such
                    material is located [e.g., URL]).
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    A statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    any agent thereof, or the law.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    A statement by you, made under penalty of perjury, that the
                    above information in your notice is accurate, and that you
                    are the copyright owner or a person authorized to act on the
                    copyright owner’s behalf.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    An electronic or physical signature of the person authorized
                    to act on the copyright owner’s behalf.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                GRIPSTUDIO, LLC’s Agent for notices of claims of copyright
                infringement occurring on the Platform can be reached as
                follows:
              </Typography>

              <Typography variant="body1" sx={{ ...commonStyle }}>
                DMCA Designated Agent
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                GripStudio, LLC
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                1300 W. 19th St, Houston, TX 77248
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                PO 7188
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                Email: Legal@GripStudio.App
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Inquiries that do not comply with all of the requirements of 17
                U.S.C. § 512 may not be effective. Please be aware that if you
                knowingly materially misrepresent that material, content, or
                activity on the Platform infringes upon your copyright, you may
                be held liable for damages (including costs and attorneys’ fees)
                under 17 U.S.C. § 512(f).
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Monitoring and Enforcement; Termination"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                We have the right to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Remove or refuse to post any User Contributions for any or
                    no reason in our sole discretion.
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Take any action with respect to any User Contribution that
                    we deem necessary or appropriate in our sole discretion,
                    including if we believe that such User Contribution violates
                    the Terms of Use, including the Content Standards, infringes
                    any intellectual property right or other right of any person
                    or entity, threatens the personal safety of Users of the
                    Platform or the public, or could create liability for
                    Company.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Disclose your identity or other information about you to any
                    third party who claims that material posted by you violates
                    their rights, including their intellectual property rights
                    or their right to privacy.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Take appropriate legal action, including without limitation,
                    referral to law enforcement, for any illegal or unauthorized
                    use of the Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Terminate or suspend your access to all or part of the
                    Platform for any or no reason, including without limitation,
                    any violation of these Terms of Use.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Without limiting the foregoing, we have the right to cooperate
                fully with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the
                Platform. YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS
                AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
                DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER
                SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                However, we cannot review material before it is posted on the
                Platform, and cannot ensure prompt removal of objectionable
                material after it has been posted. Accordingly, we assume no
                liability for any action or inaction regarding transmissions,
                communications, or content provided by any User or third party.
                We have no liability or responsibility to anyone for performance
                or nonperformance of the activities described in this section.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Content Standards"
          para={
            <>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                These content standards apply to any and all User Contributions
                and use of Interactive Services. User Contributions must in
                their entirety comply with all applicable federal, state, local,
                and international laws and regulations. Without limiting the
                foregoing, User Contributions must not:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Contain any material that is defamatory, obscene, indecent,
                    abusive, offensive, harassing, violent, hateful,
                    inflammatory, or otherwise objectionable.
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Promote sexually explicit or pornographic material,
                    violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation, or age.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Infringe any patent, trademark, trade secret, copyright, or
                    other intellectual property or other rights of any other
                    person.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Violate the legal rights (including the rights of publicity
                    and privacy) of others or contain any material that could
                    give rise to any civil or criminal liability under
                    applicable laws or regulations or that otherwise may be in
                    conflict with these Terms of Use and our Privacy Policy.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Be likely to deceive any person.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Promote any illegal activity, or advocate, promote, or
                    assist any unlawful act.
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Cause annoyance, inconvenience, or needless anxiety or be
                    likely to upset, embarrass, alarm, or annoy any other
                    person.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Impersonate any person, or misrepresent your identity or
                    affiliation with any person or organization.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Involve commercial activities or sales, such as contests,
                    sweepstakes, and other sales promotions, barter, or
                    advertising.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Give the impression that they emanate from or are endorsed
                    by us or any other person or entity, if this is not the
                    case.
                  </Typography>
                </ListItem>
              </List>
            </>
          }
        />
        <CommonBulletPoint
          title="Reliance on Information Posted"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The information presented on or through the Platform is made
                available solely for general information purposes. We do not
                warrant the accuracy, completeness, or usefulness of this
                information. Any reliance you place on such information is
                strictly at your own risk. We disclaim all liability and
                responsibility arising from any reliance placed on such
                materials by you or any other visitor to the Platform, or by
                anyone who may be informed of any of its contents.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                This Platform may include content provided by third parties,
                including materials provided by other Users, bloggers, and
                third-party licensors, syndicators, aggregators, and/or
                reporting services. All statements and/or opinions expressed in
                these materials, and all articles and responses to questions and
                other content, other than the content provided by Company, are
                solely the opinions and the responsibility of the person or
                entity providing 6those materials. These materials do not
                necessarily reflect the opinion of Company. We are not
                responsible, or liable to you or any third party, for the
                content or accuracy of any materials provided by any third
                parties.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Changes to the Platform"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may update the content on this Platform from time to time,
                but its content is not necessarily complete or up- to-date. Any
                of the material on the Platform may be out of date at any given
                time, and we are under no obligation to update such material.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Information About You and Your Visits to the Platform"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                All information we collect on this Platform is subject to our
                Privacy Policy. By using the Platform, you consent to all
                actions taken by us with respect to your information in
                compliance with the Privacy Policy.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Online Purchases and Other Terms and Conditions"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                GRIPSTUDIO, LLC does not personally sell or rent any goods and
                does not provide services other than facilitating bookings with
                third-party merchants. We are not responsible for any purchase,
                sale, or rental agreements entered into through our Platform, or
                other transactions for the sale of goods or services formed
                through the Platform, or resulting from visits made by you. We
                are not responsible for any goods or services purchased or
                rented through the Platform. Third party merchants are
                responsible for the purchase, sale, or rental of all goods and
                services offered through the Platform.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Additional terms and conditions may also apply to specific
                portions, services, or features of the Platform. All such
                additional terms and conditions are hereby incorporated by
                reference into these Terms of Use.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Bookings on Behalf of Minors"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Our Platform allows Users having the right, authority, and
                capacity to book and schedule third party services on behalf of
                minors under the age of 18, but at least 13 years old, who
                reside in the United States or any of its territories or
                possessions (“Minors”). GRIPSTUDIO, LLC does not research or
                otherwise confirm the adequacy, aptitude, or history of third
                party merchants, or their employees, agents, independent
                contractors, partners, or vendors, offered on the Platform.
                GRIPSTUDIO, LLC will not be liable or responsible under any
                circumstances for any losses, harms, liabilities, costs, and
                expenses of every kind and nature, known and unknown, arising
                out of a dispute between You or Minor, and a third party
                merchant or third party service provider. If You are a parent or
                legal guardian of a Minor, you hereby agree to bind the Minor to
                these Terms of Use and to fully indemnify and hold harmless the
                Company if the Minor breaches any of these Terms of Use.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Payment Method"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                When participating in certain transactions through the Platform,
                such as purchasing or renting products, including but not
                limited to consumable items, exercise equipment, single-use
                items, or other items as they may be generally offered by third
                parties, or purchasing services through the Platform (any of the
                preceding a “Transaction”), you must provide a valid and current
                method of payment, which may include payment through a third
                party (a “Payment Method”). You hereby grant Company the
                permission to use your Payment Method for completion of any
                payments for any Transaction, and to provide your Payment Method
                to a third-party for completion of any payments for such
                Transaction as may be needed. All applicable fees and any other
                charges you may incur in connection with the Transaction, such
                as taxes, shipping, and other fees will be charged to your
                Payment Method upon your completion of the Transaction.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Certain Transactions may be subject to ongoing costs or fees,
                such as monthly payments associated with a rental Transaction,
                and costs or fees resulting from your violation of these Terms
                of Use, including any other terms or documents incorporated
                herein, such as may relate to damage, destruction or loss of
                equipment while in your possession. You agree that all such fees
                and charges will be charged to your Payment Method in the time
                and manner that you agree to as part of these Terms of Use, or
                that are communicated to you via the Platform. If your Payment
                Method is provided through a third party, additional details
                related to your Payment Method may be available from the
                applicable third party. You authorize Company and such third
                party to charge any Payment Method associated with your account.
                Following any update to your Payment Methods, you authorize
                Company and such third party to continue to charge any newly
                added or modified Payment Methods, and, to the extent that such
                newly added or modified Payment Method fails or is unusable for
                any reason, to continue charging any prior Payment Method.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Linking to the Platform and Social Media Features"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You may link to portions of the Platform where such
                functionality is provided, provided you do so in a way that is
                fair and legal and does not damage our reputation or take
                advantage of it, but you must not establish a link in such a way
                as to suggest any form of association, approval, or endorsement
                on our part.
              </Typography>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                This Platform may provide certain social media features that
                enable you to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Link from your own or certain third-party websites to
                    certain content on this Platform.
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Send emails or other communications with certain content, or
                    links to certain content, on this Platform.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Cause limited portions of content on this Platform to be
                    displayed or appear to be displayed on your own or certain
                    third-party websites.
                  </Typography>
                </ListItem>
              </List>
              <Typography variant="body1" sx={{ ...commonStyle }}>
                You may use these features solely as they are provided by us,
                and solely with respect to the content they are displayed with.
                Subject to the foregoing, you must not:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Establish a link from any website that is not owned by you.
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Cause the Platform or portions of it to be displayed on, or
                    appear to be displayed by, any other site, for example,
                    framing, deep linking, or in-line linking.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Link to any part of the Platform other than the homepage.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="inherit" />
                  </ListItemIcon>
                  <Typography variant="body1" sx={{ ...commonStyle }}>
                    Otherwise take any action with respect to the materials on
                    this Platform that is inconsistent with any other provision
                    of these Terms of Use.
                  </Typography>
                </ListItem>
              </List>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The website from which you are linking, or on which you make
                certain content accessible, must comply in all respects with the
                Content Standards set out in these Terms of Use.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You agree to cooperate with us in causing any unauthorized
                framing or linking immediately to stop. We reserve the right to
                withdraw linking permission without notice.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We may disable all or any social media features and any links at
                any time without notice in our discretion.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Linking to the Platform and Social Media Features"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If the Platform contains links to other sites and resources
                provided by third parties, these links are provided for your
                convenience only. This includes links contained in
                advertisements, including banner advertisements and sponsored
                links. We have no control over the contents of those sites or
                resources, and accept no responsibility for them or for any loss
                or damage that may arise from your use of them. If you decide to
                access any of the third- party websites linked to this Platform,
                you do so entirely at your own risk and subject to the terms and
                conditions of use for such websites.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Geographic Restrictions"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                We provide this Platform for use only by persons located in the
                United States. We make no claims that the Platform or any of its
                content is accessible or appropriate outside of the United
                States. Access to the Platform may not be legal by certain
                persons or in certain countries. If you access the Platform from
                outside the United States, you do so on your own initiative and
                are responsible for compliance with local laws.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Disclaimer of Warranties"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the internet or the
                Platform will be free of viruses or other destructive code. You
                are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED
                BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR
                ITEMS OBTAINED THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF
                ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                YOUR USE OF THE PLATFORM, ITS CONTENT, AND ANY SERVICES, ITEMS,
                OR GOODS OBTAINED THROUGH THE PLATFORM IS AT YOUR OWN RISK. THE
                PLATFORM, ITS CONTENT, AND ANY SERVICES, GOODS, OR ITEMS
                OBTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS
                AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER
                EXPRESS OR IMPLIED. NEITHER COMPANY NOR ANY PERSON ASSOCIATED
                WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
                TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                OR AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING,
                NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR
                WARRANTS THAT THE PLATFORM, ITS CONTENT, OR ANY SERVICES, GOODS,
                OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE,
                RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
                ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
                PLATFORM OR ANY SERVICES, GOODS, OR ITEMS OBTAINED THROUGH THE
                PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                TO THE FULLEST EXTENT PROVIDED BY LAW, COMPANY HEREBY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
                WARRANTIES OF MERCHANTABILITY, NON- INFRINGEMENT, AND FITNESS
                FOR PARTICULAR PURPOSE.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </Typography>
            </>
          }
        />

        <CommonBulletPoint
          title="Limitation on Liability"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL COMPANY,
                ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES
                OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                CONNECTION WITH ANY GOODS PROVIDED OR ANY SERVICES RENDERED BY
                THIRD PARTY MERCHANTS, YOUR USE, OR INABILITY TO USE, THE
                PLATFORM, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE PLATFORM
                OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT
                NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
                DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. IN NO EVENT WILL
                THE COLLECTIVE LIABILITY OF COMPANY AND ITS SUBSIDIARIES AND
                AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
                AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE
                FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED
                THE AMOUNT YOU HAVE PAID TO COMPANY, FOR THE APPLICABLE PRODUCT
                OR SERVICE OUT OF WHICH LIABILITY AROSE, IN THE LAST 12 MONTHS.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Indemnification"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You agree to defend, indemnify, and hold harmless Company, its
                affiliates, licensors, and service providers, and its and their
                respective officers, directors, employees, contractors, agents,
                licensors, suppliers, successors, and assigns from and against
                any claims, liabilities, damages, judgments, awards, losses,
                costs, expenses, or fees (including reasonable attorneys’ fees)
                arising out of or relating to your violation of these Terms of
                Use or your use of the Platform, including, but not limited to,
                your User Contributions, any use of the Platform’s content,
                services, and products other than as expressly authorized in
                these Terms of Use, or your use of any information obtained from
                the Platform.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Governing Law and Jurisdiction"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                All matters relating to the Platform and these Terms of Use, and
                any dispute or claim arising therefrom or related thereto (in
                each case, including non-contractual disputes or claims), shall
                be governed by and construed in accordance with the internal
                laws of Texas without giving effect to any choice or conflict of
                law provision or rule (whether of the State of Texas or any
                other jurisdiction).
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                Any legal suit, action, or proceeding arising out of, or related
                to, these Terms of Use or the Platform shall be instituted
                exclusively in the federal courts of the United States or the
                courts of the state of Texas, although we retain the right to
                bring any suit, action, or proceeding against you for breach of
                these Terms of Use in your country of residence or any other
                relevant country. You waive any and all objections to the
                exercise of jurisdiction over you by such courts and to venue in
                such courts.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Limitation on Time to File Claims"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE PLATFORM MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Waiver and Severability"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                No waiver by Company of any term or condition set out in these
                Terms of Use shall be deemed a further or continuing waiver of
                such term or condition or a waiver of any other term or
                condition, and any failure of Company to assert a right or
                provision under these Terms of Use shall not constitute a waiver
                of such right or provision.
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If any provision of these Terms of Use is held by a court or
                other tribunal of competent jurisdiction to be invalid, illegal,
                or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full
                force and effect.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Feedback"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                If you send or transmit any communications or materials to
                Company by mail, email, telephone, or otherwise, suggesting or
                recommending changes to the Platform, including without
                limitation, new features or functionality relating thereto, or
                any comments, questions, suggestions, or the like (“Feedback”),
                Company is free to use such Feedback irrespective of any other
                obligation or limitation between the parties governing such
                Feedback. You hereby assign to Company all right, title, and
                interest in, and Company is free to use, without any attribution
                or compensation to any party, any ideas, know-how, concepts,
                techniques, or other intellectual property rights contained in
                the Feedback, for any purpose whatsoever, although Company is
                not required to use any Feedback.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Assignment"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                You may not assign, transfer, or delegate any or all of your
                rights or obligations under these Terms of Use. Company may
                assign, transfer, or delegate any or all of its rights and
                obligations under these Terms of Use to a third party, including
                to a successor of all or substantially all of the assets of
                Company through merger, reorganization, consolidation, or
                acquisition, and including the transfer of any rights, licenses,
                or consents related to the storage and use of Feedback,
                Aggregate Data, User Contributions, and other data and
                information.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Relationship of the Parties"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                To the extent that the Terms of Use or any incorporated
                documents create any relationship between the parties, that
                relationship shall be that of an independent contractor
                relationship. Nothing contained in the Terms of Use shall be
                construed to: (i) give any party the power to direct and control
                the day-to-day activities of the other, (ii) constitute the
                parties as partners, joint venturers, co-owners, or otherwise as
                participants in a joint or common undertaking, or (iii)
                constitute any party, its agents, or employees as employees of
                any other party or grant any of them the power or authority to
                act for, bind, or otherwise create or assume any obligation on
                behalf of any of the other parties for any purpose whatsoever.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Consent to Receive Communications"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                By providing your email, phone number, or other contact
                information to the Platform for the purpose of receiving certain
                communications from or related to the Platform, you consent to
                receiving such communications from Company, which may include
                automated emails, electronic messages, or other electronic
                communications, may include automated voice calls, and may
                include other similar communications. Consent is not a condition
                of any purchase. Message and data rates may apply. Message
                frequency varies. You can unsubscribe from any such
                communications at any time by updating your account settings
                and/or following the instructions provided with such
                communication (e.g., by clicking an “Unsubscribe” link in an
                email, or by replying STOP to a text message). For additional
                information, view our Privacy Policy.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Entire Agreement"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The Terms of Use and our Privacy Policy constitute the sole and
                entire agreement between you and Company regarding the Platform
                and supersede all prior and contemporaneous understandings,
                agreements, representations, and warranties, both written and
                oral, regarding the Platform.
              </Typography>
            </>
          }
        />
        <CommonBulletPoint
          title="Your Comments and Concerns"
          para={
            <>
              <Typography
                variant="body1"
                sx={{ ...commonStyle, marginBottom: "2rem" }}
              >
                The Platform, including any software applications, websites, or
                other services, is operated by GRIPSTUDIO, LLC, and all
                feedback, comments, requests for technical support, and other
                communications relating to the Platform should be directed to:
                Hello@GripStudio.App
              </Typography>
            </>
          }
        />
      </Container>
    </Grid>
  );
};

export default TermsAndConditions;
